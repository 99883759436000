import classes from './RockawayBadge.module.css';

import { Box, type BoxProps } from '@mantine/core';
import { IconHeart } from '@tabler/icons-react';
import cx from 'clsx';

interface RockawayBadgeProps extends Omit<BoxProps, 'component'> {}

export function RockawayBadge({ className, ...others }: RockawayBadgeProps) {
  return (
    <Box
      component="a"
      href="https://rockawayx.com/"
      target="_blank"
      rel="noreferrer"
      className={cx(classes.root, className)}
      title="Made by RockawayX Labs"
      {...others}>

      Made by
      <span className={classes.title}>
        {' '}
        Rockaway<sup>X</sup> Labs{' '}
      </span>
      with <IconHeart size="1rem" className={classes.icon} stroke={1} />
    </Box>);

}