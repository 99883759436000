import classes from './route.module.css';

import { Anchor, Container, Group, Stack, Text, Title } from '@mantine/core';
import { Outlet } from '@remix-run/react';
import cx from 'clsx';

import { LinkBuilder } from '@rockawayxlabs/observatory-utils';

import { ColorSchemeSwitch } from '~/components/ColorSchemeSwitch';
import { Logo } from '~/components/Logo';
import { Link, NavLink } from '~/features/links';

import { RockawayBadge } from './components/RockawayBadge';

export default function LandingPageLayout() {
  return (
    <main className={classes.root}>
      <Container className={classes.container}>
        <Stack gap="xl" py="xl">
          <header className={classes.header}>
            <Link to={LinkBuilder.home().toString()}>
              <Title ta="center" title="Observatory">
                <span style={{ display: 'none' }}>Observatory</span>
                <Logo size={160} />
              </Title>
            </Link>
            <Group justify="flex-end" gap="lg">
              <NavLink to="faq" className={({ isActive }) => cx(classes.link, { [classes.linkInactive || '']: !isActive })}>
                <Text size="sm">FAQ</Text>
              </NavLink>
              <NavLink
                to={LinkBuilder.whitepaper().toString()}
                className={({ isActive }) => cx(classes.link, { [classes.linkInactive || '']: !isActive })}>

                <Text size="sm">Whitepaper</Text>
              </NavLink>
              <NavLink
                to={LinkBuilder.validators().toString()}
                className={({ isActive }) => cx(classes.link, { [classes.linkInactive || '']: !isActive })}>

                <Text size="sm">Validators</Text>
              </NavLink>
              <ColorSchemeSwitch />
            </Group>
          </header>

          <Outlet />

          <Text c="dimmed" ta="center" size="xs">
            By using the app, you agree to our{' '}
            <Anchor fw={500} component={Link} to={LinkBuilder.terms().toString()}>
              Terms of Service
            </Anchor>
            .
          </Text>
        </Stack>
      </Container>
      <RockawayBadge className={classes.rockawayBadge} />
    </main>);

}